/** @format */

import React from "react";
import {Box, Container, Heading, Icon, Stack, Text} from "@chakra-ui/react";
import {KeyServices} from "../utils/helpers";
import BuildWithUs from "../components/BuildWithUs/build-with-us";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <>
      <Container data-testid="landing-page">
        <Heading textAlign="center" color="green" fontSize="6xl" my={8}>
          Our services
        </Heading>
        <Stack
          direction={{base: "column", xl: "row"}}
          justifyContent="space-around"
          wrap="wrap"
          mt={4}
          spacing={{base: 10, sm: 5}}
        >
          {KeyServices.map(({icon, heading, description}) => (
            <Stack
              key={heading}
              direction={{base: "row", xl: "column"}}
              alignItems="center"
              spacing={8}
              position="relative"
              role="group"
            >
              <Icon as={icon} width="10rem" height="10rem" title={heading} />
              <Box>
                <Heading fontSize="xl" title={heading} my={3}>
                  {heading}
                </Heading>
                <Text
                  minWidth={{md: "400px", xl: "auto"}}
                  display={{base: "block", xl: "none"}}
                >
                  {description}
                </Text>
              </Box>
            </Stack>
          ))}
        </Stack>
      </Container>

      <Container pb={5}>
        <BuildWithUs />
      </Container>
    </>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO title="Aphro Tech Consult - Your trusted IT consult" />
);
